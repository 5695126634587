<script>
import { defineComponent, ref } from 'vue'

import { onClickOutside } from '@/utils/on-click-outside'
import { convertToClassString } from '@/utils/class'

export default defineComponent({
    name: 'KDropDown',
    components: {},
    props: {
        // eslint-disable-next-line vue/require-default-prop, vue/no-reserved-props
        class: [String, Array, Object],
        position: Object,
    },
    emits: ['close'],
    setup() {
        const target = ref(null)
        const show = ref(false)

        onClickOutside(target, () => {
            if (show.value) show.value = false
        })

        return { target, show }
    },
    data() {
        return {
            positionY: 'bottom',
            positionX: 'left',
            style: {
                top: '100%',
                bottom: 'auto',
                left: '0',
                right: 'auto',
            },
        }
    },
    computed: {
        class_list() {
            return convertToClassString(this.class)
        },
    },
    watch: {
        positionY() {
            if (this.positionY === 'top') {
                this.style['top'] = 'auto'
                this.style['bottom'] = '100%'
            } else {
                this.style['top'] = '100%'
                this.style['bottom'] = 'auto'
            }
        },
        positionX() {
            if (this.positionX === 'left') {
                this.style['left'] = '12px'
                this.style['right'] = 'auto'
            } else {
                this.style['left'] = 'auto'
                this.style['right'] = '12px'
            }
        },
    },
    mounted() {
        if (this.position) {
            this.positionY = this.position.y
            this.positionX = this.position.x
        } else if (this.$refs['target']) {
            const container = this.$refs['target']
            const rect = container.getBoundingClientRect()
            // const topDistance = rect.top
            // const leftDistance = rect.left
            let rightDistance = 0
            let bottomDistance = 0
            if (typeof window != 'undefined') {
                rightDistance = window.innerWidth - rect.right
                bottomDistance = window.innerHeight - rect.bottom
            }

            if (bottomDistance < 200) {
                this.positionY = 'top'
            }

            if (rightDistance < 200) {
                this.positionX = 'right'
            }
        }
    },
    methods: {
        click() {
            this.show = !this.show
        },
        close() {
            this.show = false
        },
    },
})
</script>

<template>
    <div ref="target" class="relative">
        <slot name="trigger" :click="click" :close="close" :show="show" />
        <Transition
            enter-from-class="translate-y-4 opacity-0"
            enter-active-class="transition duration-300">
            <div
                v-show="show"
                :class="class_list"
                :style="style"
                class="shadow bg-white rounded-md absolute min-w-[128px] overflow-hidden z-10">
                <slot :close="close" />
            </div>
        </Transition>
    </div>
</template>
