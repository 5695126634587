<script setup>
import GreatBritainFlag from '@/../images/flags/great-britain.jpg'
import JapanFlag from '@/../images/flags/japan.jpg'
</script>

<template>
    <KDropDown>
        <template #trigger="{ click }">
            <KButton icon="GlobeAltIcon" mode="icon" @click="click" />
        </template>
        <template #default="{ close }">
            <KButton
                :disabled="$root.is_english"
                :class="{
                    'bg-indigo-light--background !text-brand': $root.lang === 'en',
                }"
                class="whitespace-nowrap"
                mode="text"
                @click="
                    () => {
                        $root.setLanguage('en')
                        close()
                    }
                ">
                <div class="flex items-center">
                    <img :src="GreatBritainFlag" class="w-5 mr-3 object-contain" />
                    English
                </div>
            </KButton>
            <KButton
                :disabled="$root.is_japanese"
                :class="{
                    'bg-indigo-light--background !text-brand': $root.lang === 'ja',
                }"
                class="whitespace-nowrap"
                mode="text"
                @click="
                    () => {
                        $root.setLanguage('ja')
                        close()
                    }
                ">
                <div class="flex items-center">
                    <img :src="JapanFlag" class="w-5 mr-3 object-contain" />
                    日本語
                </div>
            </KButton>
        </template>
    </KDropDown>
</template>
