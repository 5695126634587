/**
 * Processes and adds schema.org data to the page
 * @param {Object|Array} nodes - Schema nodes to be processed
 */
export function useSchemaOrg(nodes) {
    if (!nodes) return null

    // Convert single node to array if needed
    const nodeArray = Array.isArray(nodes) ? nodes : [nodes]

    // Process and normalize nodes
    const processedNodes = normalizeSchemaNodes(nodeArray)

    // Create the complete schema object
    return {
        '@context': 'https://schema.org',
        '@graph': processedNodes,
    }
}

/**
 * Normalizes schema nodes by removing empty properties and handling duplicates
 * @param {Array} nodes - Array of schema.org nodes
 * @returns {Array} - Normalized nodes
 */
function normalizeSchemaNodes(nodes) {
    if (!nodes?.length) return []

    // Remove duplicate @context properties to avoid redundancy
    const cleanedNodes = nodes.map(node => {
        // Create a copy of the node
        const nodeCopy = { ...node }

        // Remove @context from individual nodes as it will be included at the root level
        delete nodeCopy['@context']

        return nodeCopy
    })

    // Strip empty properties from all nodes
    const strippedNodes = cleanedNodes.map(stripEmptyProperties)

    // Remove duplicate nodes
    return deduplicateNodes(strippedNodes)
}

/**
 * Removes empty properties from an object
 * @param {Object} obj - Object to clean
 * @returns {Object} - Cleaned object
 */
function stripEmptyProperties(obj) {
    if (!obj) return obj

    // Handle different types
    if (Array.isArray(obj)) {
        // For arrays, process each element and filter out empty ones
        return obj.map(stripEmptyProperties).filter(item => {
            if (item === null || item === undefined || item === '') return false
            if (typeof item === 'object' && Object.keys(item).length === 0) return false
            return true
        })
    }

    if (typeof obj !== 'object' || obj === null) return obj

    const result = { ...obj }

    Object.keys(result).forEach(key => {
        if (result[key] && typeof result[key] === 'object') {
            // Recursively clean nested objects
            result[key] = stripEmptyProperties(result[key])

            // Remove if the object became empty
            if (Array.isArray(result[key])) {
                if (result[key].length === 0) {
                    delete result[key]
                }
            } else if (Object.keys(result[key]).length === 0) {
                delete result[key]
            }
        } else if (result[key] === '' || result[key] === null || result[key] === undefined) {
            // Remove empty primitive values
            delete result[key]
        }
    })

    return result
}

/**
 * Removes duplicate schema.org nodes
 * @param {Array} nodes - Array of nodes
 * @returns {Array} - Deduplicated nodes
 */
function deduplicateNodes(nodes) {
    // Use a Map to efficiently track unique nodes by type+id
    const uniqueNodes = new Map()

    nodes.forEach(node => {
        if (!node) return

        // Create a key based on @type and name/identifier properties
        const nodeType = Array.isArray(node['@type']) ? node['@type'][0] : node['@type']
        const nodeId = node['@id'] || node.name || JSON.stringify(node)
        const nodeKey = `${nodeType}-${nodeId}`

        // If this type of node already exists, merge them
        if (uniqueNodes.has(nodeKey)) {
            uniqueNodes.set(nodeKey, mergeNodes(uniqueNodes.get(nodeKey), node))
        } else {
            uniqueNodes.set(nodeKey, node)
        }
    })

    return Array.from(uniqueNodes.values())
}

/**
 * Merges two nodes, properly handling arrays and objects
 * @param {Object} target - Target node
 * @param {Object} source - Source node
 * @returns {Object} - Merged node
 */
function mergeNodes(target, source) {
    const result = { ...target }

    Object.keys(source).forEach(key => {
        // If both are arrays, concatenate them
        if (Array.isArray(source[key]) && Array.isArray(result[key])) {
            result[key] = [...result[key], ...source[key]]
            // Remove duplicates if they're primitive values
            if (typeof result[key][0] !== 'object') {
                result[key] = [...new Set(result[key])]
            }
        }
        // If both are objects (but not arrays), recursively merge
        else if (
            typeof source[key] === 'object' &&
            source[key] !== null &&
            typeof result[key] === 'object' &&
            result[key] !== null &&
            !Array.isArray(source[key]) &&
            !Array.isArray(result[key])
        ) {
            result[key] = mergeNodes(result[key], source[key])
        }
        // Otherwise, prefer source value
        else {
            result[key] = source[key]
        }
    })

    return result
}
