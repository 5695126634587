const CONSTANTS = {
    BASE_URL: 'https://kinchaku.me',
    COMPANY_PHONE: '+81-50-1809-4495',
    COMPANY_EMAIL: 'help@kinchaku.com',
    LOGO_URL:
        'https://kinchaku-static-site.s3-ap-northeast-1.amazonaws.com/uploads/landing-theme/kinchaku_square.png',
    SCREENSHOT_URL:
        'https://cdn.prod.website-files.com/658d2975ecbc3a7acdb02088/65a60e0497edb08172a367d2_Images%20in%20Link%20Shares.png',
    SOCIAL_LINKS: [
        'https://www.facebook.com/kinchaku/',
        'https://www.wantedly.com/companies/kinchaku/',
        'https://www.instagram.com/kinchaku.fukuoka/',
        'https://x.com/KINCHAKU_BI',
    ],
}

/**
 * Creates contact point data
 * @returns {ContactPoint}
 */
function createContactPoint() {
    return {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        telephone: CONSTANTS.COMPANY_PHONE,
        email: CONSTANTS.COMPANY_EMAIL,
        availableLanguage: [
            { '@type': 'Language', name: 'English' },
            { '@type': 'Language', name: 'Japanese' },
        ],
    }
}

/**
 * Creates price specification object
 * @param {string} name - Plan name
 * @param {string} price - Plan price
 * @param {string} unitCode - Time unit code
 * @returns {PriceSpecification}
 */
function createPriceSpec(name, price, unitCode) {
    return {
        '@type': 'UnitPriceSpecification',
        price,
        priceCurrency: 'JPY',
        name,
        referenceQuantity: {
            '@type': 'QuantitativeValue',
            value: '1',
            unitCode,
        },
    }
}

/**
 * @returns {MetaData}
 */
export function useKinchakuSeoMeta() {
    return {
        title: '電子券・デジタルパスプラットフォームKINCHAKU',
        robots: 'index, follow',
        keywords:
            'Kinchaku, digital pass, mobile wallet, store management, customer engagement',
        ogImage: CONSTANTS.SCREENSHOT_URL,
        ogLocale: 'ja',
        ogLocaleAlternate: ['en'],
        ogDescription:
            'KINCHAKUは、紙での発券や管理が不要となるデジタルパスの発行・販売、予約・決済、顧客・売上管理やリピータ獲得等をワンストップで行えます。',
        ogTitle: '電子券・デジタルパスプラットフォームKINCHAKU',
        ogUrl: CONSTANTS.BASE_URL,
    }
}

/**
 * Creates breadcrumb schema data
 * @returns {Object}
 */
export function kinchakuBreadcrumbs() {
    const breadcrumbItems = [
        { name: 'Home', path: '' },
        { name: 'Sign In / Create Account', path: 'signin' },
        { name: 'Privacy Policy', path: 'privacy' },
        { name: 'Service terms', path: 'terms' },
    ]

    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbItems.map((item, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            name: item.name,
            item: `${CONSTANTS.BASE_URL}/${item.path}`,
        })),
    }
}

/**
 * Creates organization schema data
 * @returns {Object}
 */
export function kinchakuSeoOrg() {
    return {
        '@context': 'https://schema.org',
        '@type': 'Corporation',
        url: CONSTANTS.BASE_URL,
        name: [
            { '@language': 'en', '@value': 'KINCHAKU Inc.' },
            { '@language': 'ja', '@value': '株式会社KINCHAKU' },
        ],
        logo: [
            {
                '@type': 'ImageObject',
                url: CONSTANTS.LOGO_URL,
                width: '200',
                height: '200',
            },
        ],
        description: [
            {
                '@language': 'en',
                '@value':
                    'KINCHAKU is a technology venture startup dedicated to providing innovative digital pass solutions for small and medium-sized businesses.',
            },
            {
                '@language': 'ja',
                '@value':
                    '株式会社KINCHAKUは中小企業の生産性向上を使命に掲げ、お客様に最高のサービスを提供し、日常のあらゆるシーンをスマートで快適なものに変えるために研究開発を行なっているテクノロジーカンパニーです。',
            },
        ],
        sameAs: CONSTANTS.SOCIAL_LINKS,
        contactPoint: createContactPoint(),
    }
}

/**
 * Creates web application schema data
 * @returns {Object}
 */
export function kinchakuSeoWebApp() {
    const schemaOrg = kinchakuSeoOrg()

    const priceSpecifications = [
        createPriceSpec('Standard Plan | 月次サブスクリプション', '9900', 'MON'),
        createPriceSpec('Standard Plan | 年次サブスクリプション', '95000', 'ANN'),
        createPriceSpec('eTicket Plan | 月次サブスクリプション', '220000', 'MON'),
    ]

    return {
        '@context': 'http://schema.org',
        '@type': 'WebApplication',
        '@id': 'kinchaku',
        applicationCategory: 'BusinessApplication',
        name: 'KINCHAKU',
        operatingSystem: 'all',
        browserRequirements: 'Requires Javascript and HTML5 support',
        url: CONSTANTS.BASE_URL,
        screenshot: CONSTANTS.SCREENSHOT_URL,
        offers: {
            '@type': 'AggregateOffer',
            offeredBy: schemaOrg,
            highPrice: '220000',
            lowPrice: '0',
            offerCount: '3',
            priceCurrency: 'JPY',
            priceSpecification: priceSpecifications,
        },
    }
}
