<script setup lang="ts">
import { useFlags } from '@/composables/use-flags'
import { useProject } from '@/composables/use-project'
import FeedbackModal from '@/partials/feedback/FeedbackModal.vue'

const store = useStore()
const project = useProject().value as App.Data.ProjectData
const flags = useFlags().value as App.Data.Shared.FlagsData

const is_whitelabel_mode = computed(() => store.getters.is_whitelabel_mode)
const cancelation_url = computed(() => project.link_to_cancelation)
const terms_url = computed(() => store.getters.terms_url)
const privacy_url = computed(() => store.getters.privacy_url)
</script>

<template>
    <div class="border-t border-primary--border w-full bg-white">
        <div
            class="flex items-start max-w-[1800px] mx-auto px-4 py-4 justify-between md:pb-4 md:px-16 md:items-center">
            <div class="flex w-full flex-col md:items-center md:flex-row">
                <div
                    class="flex flex-1 items-center -mx-4 md:mt-0 md:mx-0 md:justify-between text-secondary--text flex-wrap order-3 md:order-1">
                    <div v-if="!is_whitelabel_mode" class="flex items-center md:-mx-4">
                        <div class="text-xs my-2 hidden md:block" style="min-width: 150px">
                            © Copyright
                            <a class="text-text--link hover:underline" href="https://kinchaku.com">
                                Kinchaku
                            </a>
                        </div>
                        <KButton
                            href="https://help.kinchaku.com/category/226-category"
                            mode="text"
                            class="w-full text-secondary--text font-medium text-sm whitespace-nowrap"
                            size="M"
                            target="_blank">
                            {{ $t('manual') }}
                        </KButton>
                    </div>
                    <div
                        v-if="is_whitelabel_mode && (terms_url || privacy_url || cancelation_url)"
                        class="flex items-center md:-mx-4">
                        <KButton
                            v-if="privacy_url"
                            :href="privacy_url"
                            mode="text"
                            type="link"
                            class="w-full text-secondary--text font-medium text-sm whitespace-nowrap"
                            size="M"
                            target="_blank">
                            {{ $t('privacy policy') }}
                        </KButton>
                        <KButton
                            v-if="terms_url"
                            :href="terms_url"
                            mode="text"
                            type="link"
                            class="w-full text-secondary--text font-medium text-sm whitespace-nowrap"
                            size="M"
                            target="_blank">
                            {{ $t('terms and conditions') }}
                        </KButton>
                        <KButton
                            v-if="cancelation_url"
                            :href="cancelation_url"
                            mode="text"
                            type="link"
                            class="w-full text-secondary--text font-medium text-sm whitespace-nowrap"
                            size="M"
                            target="_blank">
                            {{ $t('cancelation policy') }}
                        </KButton>
                    </div>
                </div>
                <div class="md:mx-4 md:px-8 md:border-r md:order-2">
                    <FeedbackModal v-if="!is_whitelabel_mode && !flags.disable_report_problem">
                        <template #trigger="{ open }">
                            <KButton
                                icon-left="WrenchScrewdriverIcon"
                                mode="secondary"
                                size="M"
                                @click="open">
                                {{ $t('send feedback') }}
                            </KButton>
                        </template>
                    </FeedbackModal>
                </div>
            </div>
            <div class="-mr-4 -my-2"><LangSwitcher /></div>
        </div>
    </div>
</template>
