import { toValue, getCurrentScope, onScopeDispose } from 'vue'

// extracted from https://github.com/vueuse/vueuse/tree/main/packages/core/onClickOutside

function getIsIOS() {
    return (
        typeof window !== 'undefined' &&
        window.navigator?.userAgent &&
        (/iP(?:ad|hone|od)/.test(window.navigator.userAgent) ||
            // The new iPad Pro Gen3 does not identify itself as iPad, but as Macintosh.
            // https://github.com/vueuse/vueuse/issues/3577
            (window?.navigator?.maxTouchPoints > 2 &&
                /iPad|Macintosh/.test(window?.navigator.userAgent)))
    )
}

function unrefElement(elRef) {
    const plain = toValue(elRef)
    return plain?.$el || plain
}

function toArray(value) {
    return Array.isArray(value) ? value : [value]
}

function tryOnScopeDispose(fn) {
    if (getCurrentScope()) {
        onScopeDispose(fn)
        return true
    }
    return false
}

const noop = () => {}
const toString = Object.prototype.toString
const isObject = val => toString.call(val) === '[object Object]'

function useEventListener(...args) {
    const cleanups = []
    const cleanup = () => {
        cleanups.forEach(fn => fn())
        cleanups.length = 0
    }

    const register = (el, event, listener, options) => {
        el.addEventListener(event, listener, options)
        return () => el.removeEventListener(event, listener, options)
    }

    const firstParamTargets = computed(() => {
        const test = toArray(toValue(args[0])).filter(e => e != null)
        return test.every(e => typeof e !== 'string') ? test : undefined
    })

    const stopWatch = watch(
        () => [
            firstParamTargets.value?.map(e => unrefElement(e)) ??
                [window].filter(e => e != null),
            toArray(toValue(firstParamTargets.value ? args[1] : args[0])),
            toArray(unref(firstParamTargets.value ? args[2] : args[1])),
            // @ts-expect-error - TypeScript gets the correct types, but somehow still complains
            toValue(firstParamTargets.value ? args[3] : args[2]),
        ],
        ([raw_targets, raw_events, raw_listeners, raw_options]) => {
            cleanup()

            if (!raw_targets?.length || !raw_events?.length || !raw_listeners?.length) return

            // create a clone of options, to avoid it being changed reactively on removal
            const optionsClone = isObject(raw_options) ? { ...raw_options } : raw_options
            cleanups.push(
                ...raw_targets.flatMap(el =>
                    raw_events.flatMap(event =>
                        raw_listeners.map(listener =>
                            register(el, event, listener, optionsClone)
                        )
                    )
                )
            )
        },
        { flush: 'post', immediate: true }
    )

    const stop = () => {
        stopWatch()
        cleanup()
    }

    tryOnScopeDispose(cleanup)

    return stop
}

export function onClickOutside(target, handler, options = {}) {
    const { ignore = [], capture = true, detectIframe = false, controls = false } = options

    if (typeof window == 'undefined') {
        return controls ? { stop: noop, cancel: noop, trigger: noop } : noop
    }

    const isIOS = getIsIOS()
    let _iOSWorkaround = false

    // Fixes: https://github.com/vueuse/vueuse/issues/1520
    // How it works: https://stackoverflow.com/a/39712411
    if (isIOS && !_iOSWorkaround) {
        _iOSWorkaround = true
        const listenerOptions = { passive: true }
        Array.from(window.document.body.children).forEach(el =>
            useEventListener(el, 'click', noop, listenerOptions)
        )
        useEventListener(window.document.documentElement, 'click', noop, listenerOptions)
    }

    let shouldListen = true

    const shouldIgnore = event => {
        return toValue(ignore).some(target => {
            if (typeof target === 'string') {
                return Array.from(window.document.querySelectorAll(target)).some(
                    el => el === event.target || event.composedPath().includes(el)
                )
            } else {
                const el = unrefElement(target)
                return el && (event.target === el || event.composedPath().includes(el))
            }
        })
    }

    /**
     * Determines if the given target has multiple root elements.
     * Referenced from: https://github.com/vuejs/test-utils/blob/ccb460be55f9f6be05ab708500a41ec8adf6f4bc/src/vue-wrapper.ts#L21
     */
    function hasMultipleRoots(target) {
        const vm = toValue(target)

        return vm && vm.$.subTree.shapeFlag === 16
    }

    function checkMultipleRoots(target, event) {
        const vm = toValue(target)
        const children = vm.$.subTree && vm.$.subTree.children

        if (children == null || !Array.isArray(children)) return false

        return children.some(
            child => child.el === event.target || event.composedPath().includes(child.el)
        )
    }

    const listener = event => {
        const el = unrefElement(target)

        if (event.target == null) return

        if (
            !(el instanceof Element) &&
            hasMultipleRoots(target) &&
            checkMultipleRoots(target, event)
        )
            return

        if (!el || el === event.target || event.composedPath().includes(el)) return

        if ('detail' in event && event.detail === 0) shouldListen = !shouldIgnore(event)

        if (!shouldListen) {
            shouldListen = true
            return
        }

        handler(event)
    }

    let isProcessingClick = false

    const cleanup = [
        useEventListener(
            window,
            'click',
            event => {
                if (!isProcessingClick) {
                    isProcessingClick = true
                    setTimeout(() => {
                        isProcessingClick = false
                    }, 0)
                    listener(event)
                }
            },
            { passive: true, capture }
        ),
        useEventListener(
            window,
            'pointerdown',
            e => {
                const el = unrefElement(target)
                shouldListen = !shouldIgnore(e) && !!(el && !e.composedPath().includes(el))
            },
            { passive: true }
        ),
        detectIframe &&
            useEventListener(
                window,
                'blur',
                event => {
                    setTimeout(() => {
                        const el = unrefElement(target)
                        if (
                            window.document.activeElement?.tagName === 'IFRAME' &&
                            !el?.contains(window.document.activeElement)
                        ) {
                            handler(event)
                        }
                    }, 0)
                },
                { passive: true }
            ),
    ].filter(Boolean)

    const stop = () => cleanup.forEach(fn => fn())

    if (controls) {
        return {
            stop,
            cancel: () => {
                shouldListen = false
            },
            trigger: event => {
                shouldListen = true
                listener(event)
                shouldListen = false
            },
        }
    }

    return stop
}
