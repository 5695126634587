<script setup lang="ts">
import { useProject } from '@/composables/use-project'
import { useAuth } from '@/composables/use-auth'

import AccountShortCard from '@/partials/auth/AccountShortCard.vue'
// import WorkspaceSwitcher from '@/components/WorkspaceSwitcher.vue'

const project = useProject().value as App.Data.ProjectData | null
const user = useAuth().value as App.Data.Shared.UserData | null
const signin_url = computed(() => (project?.link || '') + '/signin')
</script>

<template>
    <div
        id="main-menu"
        class="flex flex-col py-6 bg-primary--background md:bg-white md:rounded-md md:max-w-sm z-10">
        <div class="flex-1">
            <template v-if="!!user">
                <AccountShortCard class="px-4" :user="user" />
                <div class="m-4">
                    <KDivider />
                </div>
                <div>
                    <div class="flex items-center justify-between">
                        <KButton
                            href="/wallet"
                            prefetch
                            icon-left="WalletIcon"
                            mode="text"
                            size="M"
                            type="link">
                            {{ $t('my wallet') }}
                        </KButton>
                        <!-- <WorkspaceSwitcher v-if="$root.dev" /> -->
                    </div>
                    <KButton
                        href="/account/information"
                        prefetch
                        icon-left="ClipboardDocumentListIcon"
                        mode="text"
                        size="M"
                        type="link">
                        {{ $t('customer information') }}
                    </KButton>

                    <KButton
                        href="/account/password"
                        prefetch
                        icon-left="LockOpenIcon"
                        mode="text"
                        size="M"
                        type="link">
                        {{ $t('update password') }}
                    </KButton>

                    <KButton
                        href="/account/payment-history"
                        prefetch
                        icon-left="BanknotesIcon"
                        mode="text"
                        size="M"
                        type="link">
                        {{ $t('payment history') }}
                    </KButton>

                    <KButton
                        href="/account/payment-method"
                        prefetch
                        icon-left="CreditCardIcon"
                        mode="text"
                        size="M"
                        type="link">
                        {{ $t('payment method') }}
                    </KButton>
                </div>
                <div class="m-4"><KDivider /></div>
            </template>
            <div>
                <KButton
                    href="/privacy"
                    mode="text"
                    class="text-secondary--text font-medium text-sm"
                    size="M"
                    target="_blank"
                    type="link">
                    KINCHAKU {{ $c($t('privacy policy')) }}
                </KButton>
                <KButton
                    href="/terms"
                    mode="text"
                    class="text-secondary--text font-medium text-sm"
                    size="M"
                    target="_blank"
                    type="link">
                    KINCHAKU {{ $c($t('terms and conditions')) }}
                </KButton>
                <div class="m-4"><KDivider /></div>
            </div>
            <KButton
                v-if="!!user"
                class="text-secondary--text"
                icon-left="ArrowRightOnRectangleIcon"
                href="/signout"
                mode="text"
                size="M"
                type="button"
                method="post">
                {{ $t('sign out') }}
            </KButton>
            <KButton
                v-else
                :href="signin_url"
                icon-left="ArrowLeftOnRectangleIcon"
                class="text-brand"
                mode="text"
                size="M">
                {{ $t('sign in') }}
            </KButton>
        </div>
        <div class="text-secondary--text text-xs text-center mx-4 md:hidden">
            {{ $t('Developed by KINCHAKU INC.') }}
        </div>
    </div>
</template>
