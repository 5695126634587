import { useStore } from 'vuex'

/**
 * Generates the VenueList schema.
 * @returns {Object}
 */
export function useVenueList() {
    const store = useStore()

    const venues = computed(() => store.getters.project_venues || []).value

    if (!venues?.length) {
        return { itemListElement: [] }
    }

    const isValidVenue = venue => {
        return (
            venue?.title &&
            venue.address?.street &&
            venue.address.city &&
            venue.address.state &&
            venue.address.postcode &&
            venue.address.country
        )
    }

    const itemListElement = venues.filter(isValidVenue).map(venue => {
        const venueObject = {
            '@type': 'LocalBusiness',
            name: [
                {
                    '@language': 'en',
                    '@value': venue.title_en,
                },
                {
                    '@language': 'ja',
                    '@value': venue.title,
                },
            ].filter(o => !!o['@value']),
            address: {
                '@type': 'PostalAddress',
                streetAddress: venue.address.street,
                addressLocality: venue.address.city,
                addressRegion: venue.address.state,
                postalCode: venue.address.postcode,
                addressCountry: venue.address.country,
            },
        }

        if (venue.phone) {
            venueObject.telephone = venue.phone
        }

        return venueObject
    })

    return {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListOrder: 'https://schema.org/ItemListOrderDescending',
        name: [
            { '@language': 'en', '@value': 'available locations' },
            { '@language': 'ja', '@value': '利用可能な店舗・施設' },
        ],
        itemListElement,
    }
}

/**
 * Generates the Organization schema.
 * @returns {Object}
 */
export function useOrganizationSchema() {
    const store = useStore()

    const businessInfo = computed(() => store.getters.ws_business_info).value
    const logoUrl = computed(() => store.getters.ws_logo).value

    if (!businessInfo || !logoUrl) {
        return { logo: [] }
    }

    const { name, website } = businessInfo

    if (!website || !logoUrl) {
        return { logo: [], website: '' }
    }

    const logo = logoUrl
        ? [
              {
                  '@type': 'ImageObject',
                  url: logoUrl,
                  width: '200',
                  height: '200',
              },
          ]
        : null

    const organizationSchema = {
        '@type': 'Organization',
        name: name,
        url: website,
        logo,
    }

    if (!logo) {
        delete organizationSchema.logo
    }

    return organizationSchema
}

/**
 * Generates the Loyalty Program schema.
 * @returns {Object}
 */
export function useMemberProgram() {
    const store = useStore()

    const url = computed(() => `${store.getters.base_url}${store.getters.project.link}`).value
    const project = computed(() => store.getters.project || {}).value

    const title = project.title
    const title_en = project.title_en
    const description = project.description
    const description_en = project.description_en

    const memberProgramSchema = {
        '@type': 'ProgramMembership',
        name: [
            {
                '@language': 'en',
                '@value': title_en,
            },
            {
                '@language': 'ja',
                '@value': title,
            },
        ].filter(o => !!o['@value']),
        description: [
            {
                '@language': 'en',
                '@value': description_en,
            },
            {
                '@language': 'ja',
                '@value': description,
            },
        ].filter(o => !!o['@value']),
        url,
    }

    if (!memberProgramSchema.name.length) {
        delete memberProgramSchema.name
    }

    if (!memberProgramSchema.description.length) {
        delete memberProgramSchema.description
    }

    return memberProgramSchema
}

/**
 * Generates the Offers schema.
 * @returns {Object[]}
 */
export function useOffers() {
    const store = useStore()

    const plans = computed(() => store.getters.plans || []).value
    const priceCurrency = computed(() => store.getters.currency || 'jpy').value
    const url = computed(() => `${store.getters.base_url}${store.getters.project.link}`).value

    if (!plans.length || !priceCurrency || !url) {
        return []
    }

    const offersSchema = {
        offers: plans.map(plan => ({
            '@context': 'https://schema.org',
            '@type': 'Offer',
            price: plan.price,
            priceCurrency,
            url,
        })),
    }

    return offersSchema
}

/**
 * Generates the Event Location List schema.
 * @returns {Object[]}
 */
function useEventLocationList() {
    const store = useStore()

    const venues = computed(() => store.getters.project_venues || []).value

    if (!venues?.length) {
        return []
    }

    const locationList = venues.map(venue => ({
        '@type': 'Place',
        name: [
            {
                '@language': 'en',
                '@value': venue.title_en,
            },
            {
                '@language': 'ja',
                '@value': venue.title,
            },
        ].filter(o => !!o['@value']),
        telephone: venue.phone,
        address: {
            '@type': 'PostalAddress',
            streetAddress: venue.address.street,
            addressLocality: venue.address.city,
            addressRegion: venue.address.state,
            postalCode: venue.address.postcode,
            addressCountry: venue.address.country,
        },
    }))

    if (!locationList.length) {
        return []
    }

    return locationList
}

/**
 * Generates the Event schema.
 * @returns {Object}
 */
export function useEventSchema() {
    const store = useStore()

    const project = computed(() => store.getters.project || {}).value
    const url = computed(() => `${store.getters.base_url}${store.getters.project.link}`).value

    const startDate = project.start_date
    const title = project.title
    const title_en = project.title_en
    const description = project.description
    const description_en = project.description_en

    const offers = useOffers()
    const location = useEventLocationList()
    const artistTag = project.tags?.find(tag => tag.key === 'artist')

    if (!startDate) {
        return {
            '@context': 'https://schema.org',
            '@type': 'Product',
            ...offers,
        }
    }

    const eventSchema = {
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: [
            {
                '@language': 'en',
                '@value': title_en,
            },
            {
                '@language': 'ja',
                '@value': title,
            },
        ].filter(o => !!o['@value']),
        description: [
            {
                '@language': 'en',
                '@value': description_en,
            },
            {
                '@language': 'ja',
                '@value': description,
            },
        ].filter(o => !!o['@value']),
        startDate,
        organizer: useOrganizationSchema(),
        offers,
        location,
        url,
    }

    if (!eventSchema.name.length) {
        delete eventSchema.name
    }

    if (!eventSchema.description.length) {
        delete eventSchema.description
    }

    if (!eventSchema.offers.length) {
        delete eventSchema.offers
    }

    if (!eventSchema.location.length) {
        delete eventSchema.location
    }

    if (artistTag) {
        eventSchema.performer = {
            '@type': 'Person',
            name: artistTag.value,
        }
    }

    return eventSchema
}

/**
 * Generates the Coupon schema.
 * @returns {Object}
 */
export function useCouponSchema() {
    const store = useStore()

    const project = computed(() => store.getters.project || {}).value
    const url = computed(() => `${store.getters.base_url}${project.link}`).value
    const priceCurrency = computed(() => store.getters.currency).value

    const title = project.title
    const title_en = project.title_en
    const description = project.description
    const description_en = project.description_en

    if (!project.published_at) {
        return {}
    }

    const couponSchema = {
        '@context': 'https://schema.org',
        '@type': 'Offer',
        name: [
            {
                '@language': 'en',
                '@value': title_en,
            },
            {
                '@language': 'ja',
                '@value': title,
            },
        ].filter(o => !!o['@value']),
        description: [
            {
                '@language': 'en',
                '@value': description_en,
            },
            {
                '@language': 'ja',
                '@value': description,
            },
        ].filter(o => !!o['@value']),
        validFrom: project.start_date,
        validThrough: project.expires_at,
        price: project.amount_off,
        priceCurrency,
        url,
    }

    if (!couponSchema.name.length) {
        delete couponSchema.name
    }

    if (!couponSchema.description.length) {
        delete couponSchema.description
    }

    return couponSchema
}
